import "./libs/0-loadCSS.js";
import { support } from "./libs/0ff-supports.js";
import "./libs/0plugins.js";
import "./libs/0polyfills.js";
import "./libs/3datepicker.min.js";
import { ffgrid } from "./libs/3ff-grid.js";
import { ffslider } from "./libs/3ff-slider.js";
import "./libs/3flickity.pkgd.min.js";
import "./libs/4datepicker.fr.js";
(function ($) {
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Variables
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	var w = window,
		d = document,
		$window = $(w),
		$html = $("html"),
		$body = $("body"),
		$header = $("#header"),
		$nav = $("#nav"),
		$headerUnderNav = $header.find(".header_underNav"),
		$main = $("#main"),
		$footer = $("#footer"),
		$menuTrigger = $("#menuTrigger"),
		$stickyBars = $main.find(".stickyBar"),
		$stickyBarActive = false,
		$social,
		windowH,
		windowW,
		headerH,
		scrollT,
		scrollUp = false,
		manualScrolling = false,
		sauvScroll = 0,
		is_mobile = false,
		$adminBar = $("#wpadminbar"),
		has_adminBar = $body.hasClass("admin-bar"),
		adminBar_h = 0,
		serveur_test = d.location.hostname == "localhost",
		is_requeting = false;
	$html.addClass("js-init");

	w.setTimeout(function () {
		$html.addClass("is-siteInit");
	}, 300);

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Dimensions
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	function update_dimensions() {
		windowH = $window.height();
		windowW = $window.width();
		headerH = $headerUnderNav.height();
		update_adminbar();
		update_scroll();
		is_mobile_update();
	}
	function update_scroll() {
		if (!manualScrolling) {
			if ($window.scrollTop() < scrollT) {
				scrollUp = true;
			} else {
				scrollUp = false;
			}
		}
		manualScrolling = false;
		scrollT = $window.scrollTop();
	}

	function update_adminbar() {
		// $adminBar = !$adminBar.length && has_adminBar ? $('#wpadminbar') : $adminBar;
		// adminBar_h = $adminBar.length && $adminBar.is(':visible') ? $adminBar.height() : 0;
		adminBar_h =
			parseInt($html.css("margin-top")) + parseInt($body.css("margin-top"));
	}

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Helper functions
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	function pad(number) {
		if (number < 10) {
			return "0" + number;
		}
		return number;
	}

	function arrayIntersection(haystack, arr) {
		return arr.some(function (v) {
			return haystack.indexOf(v) >= 0;
		});
	}

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Supports
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	// if(!support.objectFit) ffclass.add(html,'no-objectfit');
	// if(!support.objectPosition) ffclass.add(html,'no-objectposition');

	var transformProp;
	(function () {
		var style = document.createElement("a").style;
		var prop;
		if (style[(prop = "webkitTransform")] !== undefined) {
			transformProp = prop;
		}
		if (style[(prop = "msTransform")] !== undefined) {
			transformProp = prop;
		}
		if (style[(prop = "transform")] !== undefined) {
			transformProp = prop;
		}
	})();

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Scroll jusqu'a un element
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	function scrollToEl($el) {
		var offsetEl = 0;
		var $offsetUsed;
		if ($el.length) {
			$offsetUsed = $el.is(":first-child")
				? $el.closest(":not(:first-child)")
				: $el;
			offsetEl = $offsetUsed.offset().top - adminBar_h;
			offsetEl -= headerH;
			offsetEl -= parseInt($offsetUsed.css("margin-top"));
			offsetEl += 2;
		}

		scrollToOffset(offsetEl);
	}

	function scrollToOffset(offsetEl) {
		$("html, body").animate(
			{
				scrollTop: offsetEl,
			},
			500
		);
	}

	var $directElToScroll = $body.find(".js-scroll-to-me").first();
	if ($directElToScroll.length) scrollToEl($directElToScroll);

	$body.find('a.js-scroller[href^="#"]').on("click", function (event) {
		event.preventDefault();
		var $target = $($(this).attr("href"));
		if ($target.length) {
			scrollToEl($target);
		}
	});

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Menu ouverture / fermeture
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	var idMenuTimeOut = false;
	$menuTrigger.on("click", function (event) {
		event.preventDefault();
		menu_toggle();
	});

	function menu_toggle() {
		if ($html.hasClass("has-menuOpen")) {
			menu_close();
		} else {
			menu_open();
		}
	}

	function menu_open() {
		sauvScroll = scrollT;
		idMenuTimeOut = w.setTimeout(function () {
			$html.addClass("is-scrollBlocked");
		}, 300);
		$html.addClass("has-menuOpen");
	}

	function menu_close() {
		if (idMenuTimeOut) w.clearTimeout(idMenuTimeOut);
		$html.removeClass("is-scrollBlocked");
		manualScrolling = true;
		w.scrollTo(0, sauvScroll);
		w.setTimeout(function () {
			$html.removeClass("has-menuOpen");
		}, 50);
		w.setTimeout(function () {
			closeSubMenu();
		}, 300);
	}

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Menu ouverture / fermeture sous-menu
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	var $nav_links = $nav.find(".nav_l");
	var $nav_items = $nav.find(".nav_i");
	var $navMobile_panels = $nav.find(".navMobile_panel");
	var $nav_links_sub = $nav.find(
		".nav_i.has-columns .navSub_list-l0 > li > a:not(:last-child)"
	);

	// Ajout de la classe .navMobile_panel pour les colonnes du sous-menu formation
	$nav_links_sub.each(function (index, el) {
		$(el).next("ul").addClass("navMobile_panel");
	});
	$navMobile_panels = $nav.find(".navMobile_panel");

	// Ajout du titre avant la liste
	$nav.find("a + .navMobile_panel").each(function (idx, el) {
		var $panel = $(el);
		var wrapper = $panel.is("ul") ? "li" : "div";
		$panel.parents("li").each(function (k, li) {
			$panel.prepend(
				"<" +
					wrapper +
					' class="navMobile_panelTitle">' +
					$(li).children(0).html() +
					"</" +
					wrapper +
					">"
			);
		});
	});

	$nav_links.on("click", function (event) {
		trigger_nav_link(this, event);
	});
	$nav_links_sub.on("click", function (event) {
		if (!is_mobile) return;
		trigger_nav_link(this, event);
	});

	function trigger_nav_link(link, event) {
		var $this = $(link);
		// var $link_item = $this.closest('.nav_i');
		// var $sub_menu = $link_item.find('.nav_sub');
		var $link_item = $this.closest("li");
		var $sub_menu = $this.next(".navMobile_panel");
		var $mobilePanelParent = $link_item.closest(".navMobile_panel");
		if (!$sub_menu.length) {
			closeSubMenu();
			return;
		}
		event.preventDefault();
		if ($link_item.hasClass("is-open")) {
			$link_item.removeClass("is-open");
			$mobilePanelParent.removeClass("has-childOpened");
			if (!$nav.find(".is-open").length) $html.removeClass("has-subnavOpen");
		} else {
			//BUG il faut fermer que les panel sur le meme niveau
			$link_item
				.siblings(".is-open")
				.removeClass("is-open")
				.find(".has-childOpened")
				.removeClass("has-childOpened");
			$link_item.addClass("is-open");
			// $link_item.find('.nav_sub').get(0).scrollTo(0, 0);
			$html.addClass("has-subnavOpen");
			$mobilePanelParent.addClass("has-childOpened");
			$sub_menu.find("input").focus();
		}
	}

	function closeSubMenu() {
		if ($html.hasClass("has-subnavOpen")) {
			$html.removeClass("has-subnavOpen");
			$nav.find(".is-open").removeClass("is-open");
			$navMobile_panels.removeClass("has-childOpened");
		}
	}

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Menu mobile
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	$header.find(".js-navmobile-close").on("click", function (event) {
		event.preventDefault();
		menu_close();
	});
	$header.find(".js-navmobile-prev").on("click", function (event) {
		event.preventDefault();
		if (!$html.hasClass("has-subnavOpen")) return;
		var $lastPanelOpen = $nav.find(".is-open > .navMobile_panel").last();
		if ($lastPanelOpen.length) {
			$lastPanelOpen.closest(".is-open").removeClass("is-open");
			$lastPanelOpen
				.parent()
				.closest(".navMobile_panel")
				.removeClass("has-childOpened");
			if (!$nav.find(".is-open").length) $html.removeClass("has-subnavOpen");
		}
	});

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Header
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	function update_header() {
		if (
			!is_mobile &&
			$header.get(0).getBoundingClientRect().top + $header.innerHeight() <=
				adminBar_h + headerH
		) {
			$header.addClass("is-fixed");
		} else {
			$header.removeClass("is-fixed");
		}
	}

	function is_mobile_update() {
		is_mobile = Math.abs($header.innerHeight() - headerH) < 3;
	}

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Page nav
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	function titlesNav(e) {
		// On vérifie de ne pas être sur une page CCN
		var $asidenav = $(".page_navPage"),
			$jsasidenav = $asidenav.find("ul"),
			$titlesnav = $main.find("h2[id]");

		// Si le node est vide on affiche pas le bloc de navigation
		if ($titlesnav.length == 0) {
			$asidenav.hide();
		} else {
			$titlesnav.each(function () {
				var $this = $(this);
				if (!$this.parent().hasClass("sectionLeftTitle_head")) {
					$jsasidenav.append(
						'<li class="aside-navigation-item"><a href="#' +
							$this.attr("id") +
							'" class="aside-navigation-link js-scroller">' +
							$this.text() +
							"</a></li>"
					);
				}
			});
		}

		/*
        Système de navigation sidebar
    */

		$jsasidenav.on("click", ".aside-navigation-link", function (e) {
			e.preventDefault();

			var target = $(this).attr("href"),
				$target = $(target);

			if (!$target.length) {
				$target = $('[data-anchor="' + target.substr(1) + '"]');
			}

			if (!$target.length) {
				return;
			}

			var scrollTop = $target.offset().top - $(".header_underNav").height() - 70,
				$adminbar = $("#wpadminbar");

			if ($adminbar.length) {
				scrollTop -= $adminbar.height();
			}

			var saveScrollTop = $(window).scrollTop();
			location.hash = target;
			$("html, body").scrollTop(saveScrollTop);

			$("html, body").stop().animate(
				{
					scrollTop: scrollTop,
				},
				600
			);

			return false;
		});
	}

	titlesNav();

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Page nav fixed
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	var $pageNavFix = $main.find(".page_nav-fix");
	var $pageNavFixInner = $pageNavFix.length
		? $pageNavFix.find(".page_navInner")
		: [];
	var $pageNavFixItems = $pageNavFixInner.length
		? $pageNavFixInner.find(".js-scroller").closest("li")
		: [];
	var $pageNavFixItemsTargets = $pageNavFixItems.length
		? $main.find("h2[id]")
		: [];

	function update_page_nav_fix() {
		if (is_mobile || !$pageNavFixInner.length) return;
		if ($pageNavFix.get(0).getBoundingClientRect().top < adminBar_h + headerH) {
			$pageNavFix.addClass("is-fixed");
		} else {
			$pageNavFix.removeClass("is-fixed");
		}
		if (
			$pageNavFix.get(0).getBoundingClientRect().top +
				$pageNavFix.innerHeight() -
				adminBar_h -
				headerH <
			$pageNavFixInner.innerHeight()
		) {
			$pageNavFix.addClass("is-abs");
		} else {
			$pageNavFix.removeClass("is-abs");
		}
		update_page_nav_fix_active_item();
	}

	function update_page_nav_fix_active_item() {
		if (!$pageNavFixItems.length || !$pageNavFixItemsTargets.length) return;
		var $activeTitle = false;
		var $activeItem = false;
		$pageNavFixItemsTargets.each(function (idx, el) {
			var $el = $(el);
			var $offsetUsed = $el.is(":first-child")
				? $el.closest(":not(:first-child)")
				: $el;
			if (
				$offsetUsed.get(0).getBoundingClientRect().top - 4 <=
				adminBar_h + headerH + parseInt($offsetUsed.css("margin-top"))
			) {
				$activeTitle = $el;
			}
		});
		if ($activeTitle && $activeTitle.length) {
			$activeItem = $pageNavFixInner
				.find('a[href="#' + $activeTitle.attr("id") + '"]')
				.closest("li");
			if (!$activeItem.hasClass("is-active")) {
				$pageNavFixItems.removeClass("is-active");
				$activeItem.addClass("is-active");
			}
		} else {
			$pageNavFixItems.removeClass("is-active");
		}
	}

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Modales
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	$body.find("[data-modal]").on("click", function (event) {
		event.preventDefault();
		var $target = $("#" + $(this).attr("data-modal"));
		if ($target.length) {
			$target.removeClass("is-hide");
			$html.addClass("has-modal");
			$target.find("input").focus();
		}
	});
	$body.find(".js-modal-close").on("click", function (event) {
		event.preventDefault();
		modale_close(this);
	});

	function modale_close(target) {
		if (target) {
			$(target).closest(".js-modal").addClass("is-hide");
		} else {
			$body.find(".js-modal:not(.is-hide)").addClass("is-hide");
		}
		$html.removeClass("has-modal");
	}

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Sliders (Flickity)
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	function sliders_init($cont) {
		var $sliders = ($cont && $cont.length ? $cont : $main).find(
			".js-slider:not(.flickity-enabled)"
		);
		if (!$sliders.length) return;

		$sliders.each(function (idx, el) {
			slider_set(el);
		});
	}
	sliders_init();

	function slider_set(slider) {
		var $slider = $(slider);
		var $slides = $slider.children();

		// var $sliderLgd = $('<p class="slider_lgdCont txt_small cg4"></p>');
		// $slider.closest(".slider").append($sliderLgd);
		// $sliderLgd.html($slides.eq(0).find(".slider_lgd").html());

		if ($slides.length < 2) return;

		flickity_set($slider);
	}

	function flickity_set($slider, isFS, initial) {
		var isSliderSmall = $slider.hasClass("slider_small");
		var $flkty = $slider.flickity({
			cellSelector: isFS ? ".fs_i" : ".slider_i",
			setGallerySize: isSliderSmall,
			resize: isSliderSmall,
			wrapAround: !isSliderSmall,
			contain: isSliderSmall,
			prevNextButtons: !isSliderSmall,
			// lazyLoad: 1, //<img src="placeholder.jpg" data-flickity-lazyload="full.jpg" />
			// lazyLoad: false,
			pageDots: false,
			initialIndex: initial || 0,
			arrowShape:
				"M58.9,8.8L24,43.9h76v12.3H24l34.9,35.1l-8.8,8.8L0,50L50.1,0L58.9,8.8z",
		});

		if (!isFS) {
			$flkty.on("select.flickity", function (event) {
				// var eventSlider = this;
				// var $eventSlider = $(eventSlider);
				// var eventFlkty = $eventSlider.data("flickity");
				// var $slideLgd = $(eventFlkty.selectedElement).find(".slider_lgd");
				// $eventSlider
				// 	.closest(".slider")
				// 	.find(".slider_lgdCont")
				// 	.html($slideLgd.length ? $slideLgd.html() : "");
			});
		} else {
			$flkty.on("select.flickity", function (event) {
				var eventSlider = this;
				var $eventSlider = $(eventSlider);
				var eventFlkty = $eventSlider.data("flickity");
				if (eventFlkty) {
					$eventSlider
						.find(".fs_idx span:first-child")
						.text(eventFlkty.selectedIndex + 1);
				}
			});
		}
	}

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Fullscreen
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	var $fullscreen = false;

	$main.find(".slider, .media").each(function (idx, el) {
		if (!$(el).closest(".txtFocus").length) fullscreen_init(el);
	});

	function fullscreen_init(cont) {
		var $cont = $(cont);
		var $btn = $(
			'<button type="button" class="js-fullscreen"><span class="icon-fullscreen"></span><span class="srOnly">plein écran</span></button>'
		);
		if ($cont.hasClass("media")) {
			$cont.children(":first-child").after($btn);
		} else {
			$cont.find(".slider_wrapper").append($btn);
		}
		$btn.on("click", fullscreen_trigger);
	}

	function fullscreen_trigger(event) {
		if (event) event.preventDefault();
		var $this = $(this);
		var $cont = $this.closest(".slider, .media");
		fullscreen_create();
		fullscreen_open();
		fullscreen_set($cont);
	}

	function fullscreen_create() {
		if ($fullscreen && $fullscreen.length) return;

		$fullscreen = $('<div class="fs" id="fs"></div>');
		$fullscreen.append(
			'<div class="fs_inner"><button type="button" class="fs_close js-close-fullscreen"><span class="icon-close"></span><span class="srOnly">Fermer le plein écran</span></button><div class="fs_content"></div></div>'
		);

		$fullscreen.find(".js-close-fullscreen").on("click", fullscreen_close);
	}

	function fullscreen_set($cont) {
		var $fullscreenContent = $fullscreen.find(".fs_content");
		var $medias = $cont.find("img, [data-id][data-type]");
		var activeSlide = 0;
		$medias.each(function (idx, el) {
			var $img = $(el);
			var imgHtml = "";
			if ($img.is("[data-id][data-type]")) {
				imgHtml = fullscreen_set_video($img);
			} else {
				imgHtml = fullscreen_set_img($img);
			}
			$fullscreenContent.append(imgHtml);
			if (
				$cont.hasClass("slider") &&
				$img.closest(".slider_i.is-selected").length
			) {
				activeSlide = idx;
			}
		});
		if ($medias.length > 1) {
			$fullscreenContent.append(
				'<p class="fs_idx f1-17"><span>' +
					(activeSlide + 1) +
					"</span> | <span>" +
					$medias.length +
					"</span></p>"
			);
			flickity_set($fullscreenContent, true, activeSlide);
		}
		init_videos();
		w.setTimeout(function () {
			$fullscreen
				.addClass("fs-loaded")
				.removeClass("has-loader")
				.removeClass("has-loader-white")
				.removeClass("is-loading");
		}, 800);
	}

	function fullscreen_set_video($video) {
		var $legende = $video.parent().find(".slider_lgd, .media_lgd");
		var imgHtml =
			'<div class="fs_i" data-plyr-poster="' +
			$video.attr("data-plyr-poster") +
			'">';
		imgHtml +=
			'<div class="js-plyr" data-plyr-provider="' +
			$video.attr("data-type") +
			'" data-plyr-embed-id="' +
			$video.attr("data-id") +
			'"></div>';
		imgHtml += $legende.length
			? '<p class="fs_lgd f1-14 txt-c">' + $legende.html() + "</p>"
			: "";
		imgHtml += "</div>";
		return imgHtml;
	}

	function fullscreen_set_img($img) {
		var $legende = $img.parent().find(".slider_lgd, .media_lgd");
		var img_src = $img.attr("data-src-fs");
		var img_srcset = $img.attr("data-srcset-fs");
		var img_sizes = $img.attr("data-sizes-fs");
		var img_alt = $img.attr("alt");
		img_src = img_src ? img_src : $img.attr("src");
		img_src = img_src ? img_src : $img.attr("data-flickity-lazyload");
		if (!img_src) return "";
		img_srcset = img_srcset ? img_srcset : $img.attr("srcset");
		img_sizes = img_sizes ? img_sizes : $img.attr("sizes");
		var imgHtml = '<figure class="fs_i">';
		imgHtml +=
			'<img class="fs_img" src="' +
			img_src +
			'"' +
			(img_srcset ? ' srcset="' + img_srcset + '"' : "") +
			(img_sizes ? ' sizes="' + img_sizes + '"' : "") +
			' alt="' +
			img_alt +
			'">';
		imgHtml += $legende.length
			? '<figcaption class="fs_lgd f1-14 txt-c">' +
			  $legende.html() +
			  "</figcaption>"
			: "";
		imgHtml += "</figure>";
		return imgHtml;
	}

	function fullscreen_close(event) {
		if (event) event.preventDefault();
		if (!$fullscreen) return;
		$fullscreen
			.removeClass("is-show")
			.removeClass("fs-loaded")
			.removeClass("has-loader")
			.removeClass("has-loader-white")
			.removeClass("is-loading");
		$html.removeClass("has-fullscreen");
		if ($fullscreen.find(".fs_content").hasClass("flickity-enabled")) {
			$fullscreen.find(".fs_content").flickity("destroy");
		}
		$fullscreen.find(".fs_content").empty();
		$fullscreen.detach();
	}

	function fullscreen_open(event) {
		if (event) event.preventDefault();
		if (!$fullscreen) return;
		$fullscreen
			.addClass("is-show")
			.addClass("has-loader")
			.addClass("has-loader-white")
			.addClass("is-loading");
		$html.addClass("has-fullscreen");
		$body.append($fullscreen);
	}

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Sliders (ff-slider)
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	var homeFocusSlider = false,
		homeFocusTimeout = false,
		homeActuSlider = false;
	function ffsliders_init($cont) {
		var $sliders = ($cont && $cont.length ? $cont : $main).find(".ffs");
		if (!$sliders.length) return;

		$sliders.each(function (idx, el) {
			if ($(el).hasClass("ffs-focus")) {
				ffslider_focus_set(el);
			} else {
				ffslider_actus_set(el);
			}
		});
	}
	ffsliders_init();

	function ffslider_focus_set(slider) {
		var $slider = $(slider);
		var $slides = $slider.find(".ffs_i");
		if ($slides.length < 2) return;

		var $dots = $('<ol class="homeFocus_nav f1-14-uc txt-b cg5b"></ol>');
		$slides.each(function (index, el) {
			$dots.append(
				'<li class="ffs_dot"><button type="button">' +
					pad(index + 1) +
					"</button></li>"
			);
		});
		$slider.append($dots);

		homeFocusSlider = new ffslider(slider, { infinite: true });
		$slider.addClass("ffs-init");
		homeFocusSlider.on("change", function (event) {
			// A savoir : L'evenement est appelé à l'initialisation
			if (homeFocusTimeout) clearTimeout(homeFocusTimeout);
			homeFocusTimeout = setTimeout(function () {
				homeFocusSlider.next();
			}, 5000);
		});
	}

	function ffslider_actus_set(slider) {
		var $slider = $(slider);
		// var $slides = $slider.find('.ffs_i');

		homeActuSlider = new ffslider(slider, { infinite: false, keys: false });
		$slider.addClass("ffs-init");
	}

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Carrousels accueil
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	$(".carousel").flickity({
		// options
		cellAlign: "left",
		contain: true,
	});

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Grille masonry
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	function init_grid($cont) {
		($cont && $cont.length ? $cont : $main)
			.find(".js-grid")
			.each(function (index, el) {
				ffgrid({ cont: el, item: "li" });
			});
	}

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Accordéons contenu
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	function acc_init($cont) {
		($cont && $cont.lengt ? $cont : $main)
			.find(".txtEncart:not(.is-init), [data-acc]:not(.is-init)")
			.each(function (idx, encart) {
				var $encart = $(encart);
				var $encartTitle = $encart.find(".txtEncart_title, [data-acc-title]");
				var $encartContent = $encart.find(".txtEncart_text, [data-acc-cont]");
				if (!$encartTitle.length || !$encartContent.length) return;
				$encartTitle.wrapInner('<button type="button"></button>');
				$encart.addClass("is-init");
				$encartContent.hide();
				$encartTitle.find("button").on("click", function (event) {
					event.preventDefault();
					$encart.toggleClass("is-open");
					$encartContent.slideToggle(400);
				});
				if ($encart.hasClass("is-active")) {
					$encart.toggleClass("is-open");
					$encartContent.show();
				}
			});
	}
	acc_init();

	var $titlesFormation = $main.find(
		".page.formation .page_text h2:not(.mobAcc_title)"
	);
	var is_acc_content = false;
	var $footerLogo = $footer.find(".footer_section-logo"); //on utilise ça pour savoir quand mettre les accordéons

	function acc_content_create() {
		$titlesFormation.each(function (idx, title) {
			var $accTitle = $(title)
				.addClass("mobAcc_title")
				.wrapInner('<button type="button"></button>');
			var $acc;
			var $accContent;
			$accTitle.nextUntil("h2").wrapAll('<div class="mobAcc_content"></div>');
			$accContent = $accTitle.next(".mobAcc_content");
			$accTitle.add($accContent).wrapAll('<div class="mobAcc is-init"></div>');
			$acc = $accTitle.parent(".mobAcc");
			$accContent.hide();
			$accTitle.find("button").on("click", function (event) {
				event.preventDefault();
				$acc.toggleClass("is-open");
				$accContent.slideToggle(400);
			});
		});
	}

	function acc_content_destroy() {
		$main.find(".mobAcc_title button").off();
		$main.find(".mobAcc").each(function (idx, acc) {
			var $acc = $(acc);
			var $accTitle = $acc.find(".mobAcc_title");
			var $accContent = $acc.find(".mobAcc_content");
			$accTitle
				.removeClass("mobAcc_title")
				.find("button")
				.contents()
				.unwrap("button");
			$accContent.removeAttr("style").contents().unwrap(".mobAcc_content");
			$acc.contents().unwrap(".mobAcc");
		});
	}

	function acc_content_check() {
		if (!$titlesFormation.length) return;
		// var cond = is_mobile;
		var cond = Math.abs($footerLogo.outerWidth() - windowW * 0.5) < 10;
		if (cond && !is_acc_content) {
			acc_content_create();
			is_acc_content = true;
		} else if (!cond && is_acc_content) {
			acc_content_destroy();
			is_acc_content = false;
		}
	}

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Accordéons Gutenberg
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	function accs_init() {
		var $accs = $main.find(".acc");
		if ($accs.length) {
			$accs.each(function (index, el) {
				acc_setup($(el));
			});
		}
	}
	accs_init();

	function acc_setup($acc) {
		$acc.addClass("is-closed").find(".acc_cont").hide();
		$acc.on("click", ".acc_btn", function (event) {
			event.preventDefault();
			acc_close_siblings($acc);
			acc_toggle($acc);
		});
	}

	function acc_toggle($acc) {
		$acc.toggleClass("is-closed").find(".acc_cont").slideToggle(400);
	}

	function acc_close_siblings($acc) {
		$acc.siblings(".acc").each(function (index, el) {
			acc_close($(el));
		});
	}

	function acc_close($acc) {
		if (!$acc.hasClass("is-closed"))
			$acc.addClass("is-closed").find(".acc_cont").slideUp(400);
	}

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Page nav : mobile
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	$main.find(".page_navMobileAcc").each(function (idx, el) {
		var $el = $(el);
		var $cont = $el.next();
		$cont.addClass("is-pageNav-hide");
		$el.find("button").on("click", function (event) {
			event.preventDefault();
			if ($el.hasClass("is-open")) {
				$cont.slideUp(400, function () {
					$el.removeClass("is-open");
					$cont.addClass("is-pageNav-hide");
					$cont.removeAttr("style");
				});
			} else {
				$el.addClass("is-open");
				$cont.slideDown(400, function () {
					$cont.removeAttr("style");
					$cont.removeClass("is-pageNav-hide").removeAttr("style");
				});
			}
		});
	});

	var $pageNavToMove = $main.find(".page_nav-mobile.to-move");
	var $pageNavElToCheck = $pageNavToMove.length
		? $pageNavToMove.find(".page_navMobileAcc")
		: [];
	var pageNav_is_move = false;
	function page_nav_mobile_move() {
		$pageNavToMove.insertAfter(".page_header");
	}

	function page_nav_mobile_move_reset() {
		$pageNavToMove.prependTo(".page_wrapper");
	}

	function page_nav_mobile_move_check() {
		if (!$pageNavToMove.length || !$pageNavElToCheck.length) return;
		// var cond = is_mobile;
		// var cond = Math.abs($pageNavToMove.outerWidth() - windowW) < 10;
		var cond = $pageNavElToCheck.is(":visible");
		if (cond && !pageNav_is_move) {
			page_nav_mobile_move();
			pageNav_is_move = true;
		} else if (!cond && pageNav_is_move) {
			page_nav_mobile_move_reset();
			pageNav_is_move = false;
		}
	}

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Téléchargements : formation initial
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	$(".has-initial-formation .download_toggleInitial").one(
		"click",
		function (event) {
			event.preventDefault();
			$(".has-initial-formation").removeClass("has-initial-formation");
			$(".download")
				.find("[data-acc].is-active")
				.addClass("is-open")
				.find("[data-acc-cont]")
				.show();
		}
	);

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Téléchargements : sélection même formation
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	$main
		.find('.download form [name="formations[]"][type="checkbox"]')
		.on("change", function (event) {
			var $this = $(this);
			if (this.checked) {
				$main
					.find(
						'.download form [name="formations[]"][type="checkbox"][value="' +
							this.value +
							'"]'
					)
					.prop("checked", true);
			} else {
				$main
					.find(
						'.download form [name="formations[]"][type="checkbox"][value="' +
							this.value +
							'"]'
					)
					.prop("checked", false);
			}
		});

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Partenaires : filtres
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	var $partenairesCont = $main.find(".partenaires");
	var $filtersPartenairesCont = $partenairesCont.length
		? $main.find(".page_nav")
		: [];
	var $partenairesRows = $partenairesCont.length
		? $partenairesCont.find("[data-categorie]")
		: [];
	partenaires_filters_init();

	function partenaires_filters_init() {
		var $partenairesFiltersTriggers = $filtersPartenairesCont.length
			? $filtersPartenairesCont.find("[data-filter]")
			: [];
		if (!$partenairesRows.length || !$partenairesFiltersTriggers.length) return;

		$partenairesFiltersTriggers.filter("a").on("click", function (event) {
			event.preventDefault();
			var $this = $(this);
			$filtersPartenairesCont.find("li.is-active").removeClass("is-active");
			$this.closest("li").addClass("is-active");
			if ($this.attr("data-filter") == "*") {
				$filtersPartenairesCont.find('[data-filter="search"]').val("");
			}
			partenaires_filters_update();
		});

		$partenairesFiltersTriggers
			.filter('[data-filter="search"]')
			.on("input", function (event) {
				partenaires_filters_update();
			});
		$partenairesFiltersTriggers
			.filter('[data-filter="search"]')
			.next("button")
			.on("click", function (event) {
				partenaires_filters_update();
			});
	}

	function partenaires_filters_update() {
		var cat_filter = $filtersPartenairesCont
			.find("li.is-active [data-filter]")
			.attr("data-filter");
		var search_filter = $filtersPartenairesCont
			.find('[data-filter="search"]')
			.val();
		if (cat_filter == "*") {
			cat_filter = false;
		}
		$partenairesCont.find(".partenaires_i").show();
		$partenairesRows.show();
		if (cat_filter || search_filter) {
			$partenairesRows
				.filter(function (idx, partenaire) {
					var $partenaire = $(partenaire);
					var partenaire_cats = [];
					var toHide = false;
					if (
						search_filter &&
						$partenaire.text().toLowerCase().indexOf(search_filter.toLowerCase()) ===
							-1
					) {
						toHide = true;
					}
					if (!toHide && cat_filter) {
						partenaire_cats =
							$partenaire.attr("data-categorie") != ""
								? $partenaire.attr("data-categorie").split(",")
								: [];
						if (!partenaire_cats.length) {
							toHide = true;
						} else if (partenaire_cats.indexOf(cat_filter) === -1) {
							toHide = true;
						}
					}
					return toHide;
				})
				.hide();
			$partenairesCont
				.find(".partenaires_i")
				.filter(function (idx, list) {
					return $(list).find(".partenaires_letterList").innerHeight() < 4;
				})
				.hide();
		}
	}

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Newsletters : filtres
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	var $newslettersCont = $main.find(".newsletters");
	var $filtersNewslettersCont = $newslettersCont.length
		? $main.find(".page_nav")
		: [];
	var $newslettersRows = $newslettersCont.length
		? $newslettersCont.find(".newsletters_i")
		: [];
	newsletters_filters_init();

	function newsletters_filters_init() {
		var $newslettersSearch = $filtersNewslettersCont.length
			? $filtersNewslettersCont.find('[data-filter="search"]')
			: [];
		if (!$newslettersRows.length || !$newslettersSearch.length) return;

		$newslettersSearch.on("input", function (event) {
			newsletters_filters_update();
		});
		$newslettersSearch.next("button").on("click", function (event) {
			newsletters_filters_update();
		});
	}

	function newsletters_filters_update() {
		var search_filter = $filtersNewslettersCont
			.find('[data-filter="search"]')
			.val();
		$newslettersRows.show();
		if (search_filter) {
			$newslettersRows
				.filter(function (idx, newsletter) {
					var $newsletter = $(newsletter);
					var toHide = false;
					if (
						search_filter &&
						$newsletter.text().toLowerCase().indexOf(search_filter.toLowerCase()) ===
							-1
					) {
						toHide = true;
					}
					return toHide;
				})
				.hide();
		}
	}

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Formations : filtres
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	var $formationsCont = $main.find(".formations");
	var $filtersCont =
		$formationsCont.length && arrayFormations ? $main.find(".filters") : [];
	var $filtersActivesCont = $filtersCont.length
		? $filtersCont.find(".js-filters-actives")
		: [];
	var filtersActives = {};
	var $formationsRows = $formationsCont.find("[data-formation-id]");
	var $filtersTriggers = $filtersCont.length
		? $filtersCont.find("[data-filter]")
		: [];
	if ($filtersActivesCont.length) {
		filters_init();
	}

	function filters_init() {
		if (
			!$filtersActivesCont.length ||
			!$filtersTriggers.length ||
			!$formationsRows.length
		)
			return;

		$filtersTriggers
			.filter('[type="checkbox"]')
			.on("change", function (event) {
				event.preventDefault();
				var $this = $(this);
				if (this.checked) {
					$filtersActivesCont.append(
						'<li><button data-filter-active="' +
							$this.attr("data-filter") +
							'" data-filter-active-value="' +
							this.value +
							'" type="button">' +
							$this.closest("label").text() +
							"</button></li>"
					);
				} else {
					$filtersActivesCont
						.find(
							'[data-filter-active="' +
								$this.attr("data-filter") +
								'"][data-filter-active-value="' +
								this.value +
								'"]'
						)
						.closest("li")
						.remove();
				}
				filters_update();
			})
			.filter(":checked")
			.trigger("change");
		$filtersTriggers
			.filter('[data-filter="search_tags"]')
			.next("button")
			.on("click", function (event) {
				event.preventDefault();
				filters_search_add();
			});
		$filtersTriggers
			.filter('[data-filter="search_tags"]')
			.on("keydown", function (event) {
				if (event.which == 13) {
					filters_search_add();
				}
			});
		$filtersTriggers
			.filter('[data-filter="duree-min"],[data-filter="duree-max"]')
			.on("input", function (event) {
				var $this = $(this);
				if ($this.val() && $this.val() != "") {
					var suffix = this.hasAttribute("data-filter-suffix")
						? $this.attr("data-filter-suffix")
						: "";
					if (
						$filtersActivesCont.find(
							'[data-filter-active="' + $this.attr("data-filter") + '"]'
						).length
					) {
						$filtersActivesCont
							.find('[data-filter-active="' + $this.attr("data-filter") + '"]')
							.attr("data-filter-active-value", this.value)
							.text(this.value + suffix);
					} else {
						$filtersActivesCont.append(
							'<li><button data-filter-active="' +
								$this.attr("data-filter") +
								'" data-filter-active-value="' +
								this.value +
								'" type="button">' +
								this.value +
								suffix +
								"</button></li>"
						);
					}
				} else {
					$filtersActivesCont
						.find('[data-filter-active="' + $this.attr("data-filter") + '"]')
						.closest("li")
						.remove();
				}
				filters_update();
			})
			.trigger("input");
		$filtersActivesCont.on("click", "button", function (event) {
			event.preventDefault();
			var $this = $(this);
			var $input = $filtersTriggers
				.filter(
					'input[type="checkbox"][data-filter="' +
						$this.attr("data-filter-active") +
						'"][value="' +
						$this.attr("data-filter-active-value") +
						'"]'
				)
				.prop("checked", false);
			$filtersTriggers
				.filter(
					'input:not([type="checkbox"])[data-filter="' +
						$this.attr("data-filter-active") +
						'"]'
				)
				.val("");
			$this.closest("li").remove();
			filters_update();
		});
		$filtersCont.find(".js-filters-reset").on("click", function (event) {
			event.preventDefault();
			$filtersActivesCont.empty();
			$filtersTriggers.filter('[type="checkbox"]').prop("checked", false);
			$filtersTriggers.filter(':not([type="checkbox"])').val("");
			filters_update();
		});
	}

	function filters_search_add() {
		var $input = $filtersTriggers.filter('[data-filter="search_tags"]');
		if ($input.val() && $input.val() != "") {
			$filtersActivesCont.append(
				'<li><button data-filter-active="' +
					$input.attr("data-filter") +
					'" type="button">' +
					$input.val() +
					"</button></li>"
			);
			$input.val("");
			filters_update();
		}
	}

	function filters_update() {
		filters_update_filters_actives_cont();
		filters_get();
		filters_list_update();
		filters_counts_update();
	}

	function filters_update_filters_actives_cont() {
		if ($filtersActivesCont.find("li").length <= 0) {
			$filtersActivesCont.closest(".filters_section").addClass("is-hide");
		} else {
			$filtersActivesCont.closest(".filters_section").removeClass("is-hide");
		}
	}

	function filters_get() {
		filtersActives = {};
		$filtersActivesCont.find("[data-filter-active]").each(function (index, el) {
			var $this = $(el);
			var val;
			if (!filtersActives[$this.attr("data-filter-active")]) {
				filtersActives[$this.attr("data-filter-active")] = [];
			}
			if (el.hasAttribute("data-filter-active-value")) {
				val = $this.attr("data-filter-active-value");
				if ($.isNumeric(val)) {
					val = parseInt(val);
				}
			} else {
				val = $this.text();
			}
			filtersActives[$this.attr("data-filter-active")].push(val);
		});
		filters_update_url();
		// console.log(filtersActives);
	}

	function filters_list_update() {
		var formationsToShow = arrayFormations.slice(0);
		for (var filter in filtersActives) {
			if (filtersActives.hasOwnProperty(filter) && filtersActives[filter]) {
				formationsToShow = formationsToShow.filter(function (formation) {
					var isShow = false;
					if (filter == "date-begin" || filter == "date-end") {
						if (!formation["dates"] || !formation["dates"].length) {
							isShow = false;
						} else if (filtersActives["date-begin"] && filtersActives["date-end"]) {
							// Si les 2 champs remplis on filtre par rapport aux 2 et on ne le fait qu'1 fois
							if (filter == "date-begin") {
								formation["dates"].forEach(function (dates) {
									if (
										!isShow &&
										dates["date-begin"] >= filtersActives["date-begin"][0] &&
										dates["date-end"] <= filtersActives["date-end"][0]
									) {
										isShow = true;
									}
								});
							} else {
								isShow = true;
							}
						} else {
							// Sinon filtre simple
							if (filter == "date-begin") {
								formation["dates"].forEach(function (dates) {
									if (
										!isShow &&
										dates["date-begin"] >= filtersActives["date-begin"][0]
									) {
										isShow = true;
									}
								});
							} else {
								formation["dates"].forEach(function (dates) {
									if (!isShow && dates["date-end"] <= filtersActives["date-end"][0]) {
										isShow = true;
									}
								});
							}
						}
					} else if (filter == "duree-min" || filter == "duree-max") {
						if (filter == "duree-min") {
							isShow =
								formation["duree"] && formation["duree"] >= filtersActives[filter][0];
						} else if (filter == "duree-max") {
							isShow =
								formation["duree"] && formation["duree"] <= filtersActives[filter][0];
						}
					} else if (filter == "search_tags") {
						filtersActives[filter].forEach(function (tag) {
							if (
								!isShow &&
								formation[filter].toLowerCase().indexOf(tag.toLowerCase()) !== -1
							) {
								isShow = true;
							}
						});
					} else {
						isShow = arrayIntersection(formation[filter], filtersActives[filter]);
					}
					return isShow;
				});
			}
		}
		formationsToShow = formationsToShow.map(function (formation) {
			return formation.id;
		});
		var nbResult = $formationsRows
			.hide()
			.filter(function (idx, el) {
				return (
					formationsToShow.indexOf(
						parseInt(el.getAttribute("data-formation-id"))
					) !== -1
				);
			})
			.show().length;
		$formationsCont
			.find(".js-filters-count")
			.text(nbResult + " " + (nbResult > 1 ? "résultats" : "résultat"));
	}

	function filters_counts_update() {
		$filtersCont.find("[data-filter-label]").each(function (idx, el) {
			var $el = $(el);
			var name = $el.attr("data-filter-label");
			if (
				filtersActives &&
				filtersActives[name] &&
				filtersActives[name].length &&
				filtersActives[name].length > 0
			) {
				$el.find("button").attr("data-nb", filtersActives[name].length);
			} else {
				$el.find("button").removeAttr("data-nb");
			}
		});
	}

	function filters_update_url() {
		var cleanLink = window.location.href.split("?")[0];
		var filterQueryArray = [];
		if (support.history) {
			for (var filter in filtersActives) {
				if (
					filtersActives.hasOwnProperty(filter) &&
					filtersActives[filter] &&
					filtersActives[filter].length
				) {
					filterQueryArray.push(
						filter + "=" + encodeURIComponent(filtersActives[filter].join("|"))
					);
				}
			}
			history.replaceState(
				{},
				"",
				cleanLink +
					(filterQueryArray.length ? "?" + filterQueryArray.join("&") : "")
			);
		}
	}

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Datepicker
	//http://t1m0n.name/air-datepicker/docs/
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	$main.find(".js-datepicker").each(function (idx, input) {
		datepicker_init(input);
	});

	function datepicker_init(input) {
		var $input = $(input);
		var datepicker = $input
			.datepicker({
				language: "fr",
				// showOtherMonths: false,
				// selectOtherMonths: false,
				autoClose: true,
				navTitles: {
					days: "M yyyy",
					months: "yyyy",
					years: "yyyy1 - yyyy2",
				},
				dateFormat: "dd/mm/yyyy",
				minDate: new Date(),
				onSelect: function (formattedDate, date, inst) {
					if ($filtersActivesCont.length) {
						var intDate = date
							? "" +
							  date.getFullYear() +
							  pad(date.getMonth() + 1) +
							  pad(date.getDate())
							: false;
						var input = inst.el;
						var $this = $(input);
						if (intDate) {
							var prefix = input.hasAttribute("data-filter-prefix")
								? $this.attr("data-filter-prefix")
								: "";
							if (
								$filtersActivesCont.find(
									'[data-filter-active="' + $this.attr("data-filter") + '"]'
								).length
							) {
								$filtersActivesCont
									.find('[data-filter-active="' + $this.attr("data-filter") + '"]')
									.attr("data-filter-active-value", intDate)
									.text(prefix + formattedDate);
							} else {
								$filtersActivesCont.append(
									'<li><button data-filter-active="' +
										$this.attr("data-filter") +
										'" data-filter-active-value="' +
										intDate +
										'" type="button">' +
										prefix +
										formattedDate +
										"</button></li>"
								);
							}
						} else {
							$filtersActivesCont
								.find('[data-filter-active="' + $this.attr("data-filter") + '"]')
								.closest("li")
								.remove();
						}
						filters_update();
					}
				},
			})
			.data("datepicker");
		if ($input.val() && $input.val() != "") {
			var arrayDate = $input.val().split("/");
			var dateInitial = false;
			arrayDate = arrayDate.map(function (num) {
				return parseInt(num);
			});
			if (
				arrayDate.length &&
				arrayDate.length == 3 &&
				arrayDate[0] <= 31 &&
				arrayDate[1] <= 12 &&
				arrayDate[2] > 2000
			) {
				dateInitial = new Date(
					arrayDate[2],
					arrayDate[1] - 1,
					arrayDate[0],
					23,
					59,
					59,
					999
				);
				if (dateInitial && dateInitial >= new Date()) {
					datepicker.selectDate(dateInitial);
				} else {
					$input.val("");
				}
			} else {
				$input.val("");
			}
		}
	}

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Select
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	$body
		.find(".select select")
		.on("focus", function (event) {
			$(this).parent().addClass("is-open");
		})
		.on("blur", function (event) {
			$(this).parent().removeClass("is-open");
		})
		.on("change", function (event) {
			if (this.value && this.value != "")
				$(this).parent().removeClass("has-placeholder");
			else $(this).parent().addClass("has-placeholder");
		})
		.trigger("change");

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Formulaires : select autre
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	$main
		.find("select.js-others")
		.on("change", function (event) {
			var $this = $(this);
			var $nextWrapper = $this.closest(".form_field").next(".form_field");
			if (this.value && this.value == "autre") {
				$nextWrapper.removeClass("is-hide").find("input").attr("required", "true");
				if ($nextWrapper.hasClass("form_field-required")) {
					$nextWrapper.find("input").attr("required", "true");
				}
				if ($this.is(":focus")) {
					$nextWrapper.find("input").focus();
				}
			} else {
				$nextWrapper.addClass("is-hide").find("input").removeAttr("required");
			}
		})
		.trigger("change");

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Formulaires : montrer / masquer des éléments avec des radio / checkboxes
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	show_hide($main.find("[data-show-id]"));
	$main.find("[data-show]").each(function (index, trigger) {
		var $trigger = $(trigger);
		show_change(trigger);
		$trigger.on("change", function (event) {
			show_change(this, true);
		});
	});

	function show_change(trigger, live) {
		var $trigger = $(trigger);
		var targets =
			$trigger.is("select") &&
			trigger.options[trigger.selectedIndex] &&
			trigger.options[trigger.selectedIndex].hasAttribute("data-show-val")
				? trigger.options[trigger.selectedIndex].getAttribute("data-show-val")
				: $trigger.data("show");
		var targets_ids = targets ? targets.split(",") : false;
		var $targets = targets_ids
			? $main.find(
					'[data-show-id="' + targets_ids.join('"],[data-show-id="') + '"]'
			  )
			: [];

		if ($trigger.is("select")) {
			$trigger.find("option").each(function (idx, el) {
				if (el.getAttribute("value") != trigger.value) {
					var opTargets = el.hasAttribute("data-show-val")
						? el.getAttribute("data-show-val")
						: false;
					var opTargets_ids = opTargets ? opTargets.split(",") : false;
					var $opTargets = opTargets_ids
						? $main.find(
								'[data-show-id="' + opTargets_ids.join('"],[data-show-id="') + '"]'
						  )
						: [];
					show_hide($opTargets);
				}
			});
			show_show($targets);
		} else if ($trigger.is(":checked")) {
			if (live && $trigger.attr("type") == "radio") {
				$trigger
					.closest("form")
					.find(
						'input[type="radio"][name="' + $trigger.attr("name") + '"][data-show]'
					)
					.not(":checked")
					.each(function (index, el) {
						show_change(el, true);
					});
			}
			// $targets.removeClass('is-hide');
			show_show($targets);
		} else if (live) {
			// $targets.addClass('is-hide');
			show_hide($targets);
		}
	}

	function show_hide($els) {
		if (!$els.length) return;
		$els.each(function (index, el) {
			var $el = $(el);
			$el
				.addClass("is-section-disabled")
				.find("input,select,textarea")
				.attr("disabled", "true");
			if ($el.data("show-type") != "disabled") $el.addClass("is-hide");
		});
	}
	function show_show($els) {
		if (!$els.length) return;
		$els.each(function (index, el) {
			var $el = $(el);
			$el
				.removeClass("is-section-disabled")
				.removeClass("is-hide")
				.find("input,select,textarea")
				.removeAttr("disabled");
			$el
				.find("[data-show-id].is-hide")
				.find("input,select,textarea")
				.attr("disabled", "true");
		});
	}

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Formulaires : bloquer le ressoumission + loader
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	var is_form_submit = false;
	$main.find(".js-form").on("submit", function (event) {
		if (is_form_submit) event.preventDefault();
		$(this)
			.find('[type="submit"]')
			.addClass("has-loader has-loader-white is-loading");
		is_form_submit = true;
	});

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Formulaires : recaptcha
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	window.recaptchaOnLoad = function () {
		grecaptcha.execute();
	};

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Formulaires via shortcode
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	$main.find(".form_shortcode").each(function () {
		var $form = $(this),
			$text = $form.find("textarea"),
			$input = $form.find("input[type=email]"),
			$button = $form.find("button"),
			$check = $form.find("input[type=checkbox]"),
			$result = $form.find(".result");

		$form.on("submit", function (e) {
			e.preventDefault();
			if ($form.hasClass("loading")) {
				return;
			}
			$text.prop("disabled", true);
			$input.prop("disabled", true);
			$button.prop("disabled", true);
			$form.addClass("loading");
			$.ajax({
				method: "POST",
				url: url.ajax,
				type: "text",
				data: {
					action: "form_shortcode",
					message: $text.val(),
					email: $input.val(),
					newsletter: $check.is(":checked") ? 1 : 0,
				},
			})
				.done(function (data) {
					if (data == 1) {
						$result.text("Merci, nous vous contacterons prochainement.");
						$text.val("");
						$input.val("");
						$input.prop("checked", false);
					} else {
						$result.text("Erreur, veuillez réessayer");
					}
				})
				.always(function () {
					$form.removeClass("loading");
					$text.prop("disabled", false);
					$input.prop("disabled", false);
					$button.prop("disabled", false);
				}); // ajax
		}); // submit
	});

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Section social
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	function social_init() {
		$social = $main.find(".js-social").first();
		if (!$social.length) return;

		$.ajax({
			url: url.ajax,
			type: "GET",
			data: {
				action: "get_social",
			},
		})
			.done(function (data) {
				if (data) {
					$social.after(data).remove();
					init_grid($social.closest(".homeSocial"));
				} else {
					$social.closest(".homeSocial").remove();
				}
			})
			.fail(function () {
				$social.closest(".homeSocial").remove();
			});
	}
	social_init();

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Newsletter
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	$("#form_nl_wrapper").on("submit", function (event) {
		event.preventDefault();
		if (is_requeting) return;

		var $form = $(this);
		var $emailInput = $("#form_nl_email");
		var emailVal = $emailInput.val();
		$form.find(".homeModNl_alert").remove();
		if (!emailVal || emailVal == "") {
			$emailInput.focus();
			return;
		} else {
			is_requeting = true;
			$form
				.find('button[type="submit"]')
				.addClass("has-loader has-loader-s is-loading");
			$.ajax({
				url: url.ajax,
				type: "POST",
				dataType: "json",
				data: { action: "newsletter_add", email: emailVal },
			})
				.done(function (data) {
					$form
						.find('button[type="submit"]')
						.parent("p")
						.after(
							'<p class="homeModNl_alert f1-16' +
								(!data.error ? " c-green" : " c-red") +
								'">' +
								data.text +
								"</p>"
						);
					if (!data.error) $emailInput.val("");
				})
				.fail(function () {
					$form
						.find('button[type="submit"]')
						.parent("p")
						.after(
							'<p class="homeModNl_alert f1-16 c-red">Erreur, veuillez réessayer.</p>'
						);
				})
				.always(function () {
					is_requeting = false;
					$form
						.find('button[type="submit"]')
						.removeClass("has-loader")
						.removeClass("has-loader-s")
						.removeClass("is-loading");
				});
		}
	});

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Actus accueil
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	var $homeActusCont = $main.find("[data-actus-cont]");
	var $homeActusFilters = $main.find("[data-actus-filters]");
	var homeActusPage = 1;

	// homeActuSlider est définie plus haut lors de l'initialisation du slider .ffs
	if (homeActuSlider) {
		homeActuSlider.on("change", function (event) {
			// A savoir : L'evenement est appelé à l'initialisation
			// Chargement des actus suivante si on est sur le dernier slide
			if ($(event.detail.slider).find(".ffs_i.is-active").is(":last-child")) {
				home_actus_get_next_actus();
			}
		});
	}

	function home_actus_get_next_actus() {
		var cat = parseInt(
			$homeActusFilters.find(".is-active a").attr("data-cat-id")
		);
		home_actus_ajax_request({ cat: cat, paged: homeActusPage + 1 }, true);
	}

	function home_actus_init() {
		if (!$homeActusCont.length) return;

		if ($homeActusFilters.length) {
			$homeActusFilters.find("[data-cat-id]").on("click", function (event) {
				event.preventDefault();
				var $this = $(this);
				var cat = parseInt($this.attr("data-cat-id"));
				if (is_requeting || $this.closest("li").hasClass("is-active")) return;
				$homeActusFilters.find(".is-active").removeClass("is-active");
				$this.closest("li").addClass("is-active");
				home_actus_ajax_request({ cat: cat, paged: 1 });
			});
		}
	}
	home_actus_init();

	function home_actus_ajax_request(ajaxArgs, append) {
		if (is_requeting) return;

		is_requeting = true;
		if (!append) {
			home_actus_ajax_empty_result();
		}

		ajaxArgs["action"] = "get_home_actus";

		$.ajax({
			url: url.ajax,
			type: "GET",
			dataType: "json",
			data: ajaxArgs,
		})
			.done(function (data) {
				// console.log(data);
				if (data.html) {
					if (!append) {
						$homeActusCont.html(data.html);
					} else {
						$homeActusCont.append(data.html);
					}
				}
				home_actus_ajax_update(data);
				if (!append) {
					setTimeout(function () {
						home_actus_get_next_actus();
					}, 300);
				}
			})
			.fail(function () {})
			.always(function () {
				if (!append) {
					home_actus_ajax_remove_loader_result();
				}
				is_requeting = false;
			});
	}

	function home_actus_ajax_empty_result() {
		$homeActusCont
			.css("height", $homeActusCont.outerHeight() + "px")
			.empty()
			.append('<span class="loader has-loader is-loading"></span>');
		$homeActusCont.closest(".ffs").find(".ffs_prev").attr("disabled", "disabled");
		$homeActusCont.closest(".ffs").find(".ffs_next").attr("disabled", "disabled");
	}

	function home_actus_ajax_remove_loader_result() {
		$homeActusCont.css("height", "").find(".loader").remove();
	}

	function home_actus_ajax_update(data) {
		homeActusPage = data.paged;
		if (homeActuSlider) {
			homeActuSlider.reset();
		}
		home_actus_update_height_cont();
	}

	function home_actus_update_height_cont() {
		if (!$homeActusCont || !$homeActusCont.length) return;

		var maxH = 0;
		$homeActusCont.find(".ffs_i").each(function (idx, el) {
			maxH = Math.max(maxH, $(el).outerHeight());
		});
		$homeActusCont.css("min-height", maxH + "px");
	}

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Image secteur, parallax
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	var $parallaxed = [];
	parallax_update_els();

	function parallax_update_els() {
		$parallaxed = $main.find(".is-parallaxed");
	}

	function parallax_update() {
		if (!$parallaxed.length) return;
		$parallaxed.each(function (index, el) {
			var $img = $(el);
			var $imgCont = $(el).parent();
			var offsetTop = $imgCont.get(0).getBoundingClientRect().top;
			var contH = $imgCont.outerHeight();
			if (offsetTop + contH > 0 && offsetTop < windowH) {
				var imgH =
					$img.outerHeight() ||
					($imgCont.outerWidth() * parseInt($img.attr("height"))) /
						parseInt($img.attr("width"));
				var hAction = $imgCont.offset().top + contH - headerH;
				var ratioTrans = Math.max(-1, Math.min(0, (scrollT * -1) / hAction)); //compris entre -1 et 0
				var realExceedH = imgH - contH;
				var maxExceedH = contH * 0.6;
				var exceedH = Math.min(maxExceedH, realExceedH);
				var difExceedH = realExceedH - exceedH;
				var focusY = parseFloat($img.data("focus-y")) || 0.5;
				el.style[transformProp] =
					"translateY(" + (-difExceedH * focusY + exceedH * ratioTrans) + "px)";
			}
		});
	}

	var $secteurHeader = $main.find(".secteur_header");
	var $secteurHeaderImg = $secteurHeader.length
		? $secteurHeader.find("img")
		: [];

	function secteur_header_init() {
		if (!$secteurHeaderImg.length) return;
		secteur_header_update();
	}
	secteur_header_init();

	function secteur_header_update() {
		if (!$secteurHeaderImg.length) return;

		var contH = $secteurHeader.outerHeight();
		var contW = $secteurHeader.outerWidth();
		if (!contH || !contW) return;
		var contRatio = contH / contW;

		var imgH = parseInt($secteurHeaderImg.attr("height"));
		var imgW = parseInt($secteurHeaderImg.attr("width"));
		if (!imgH || !imgW) return;
		var imgRatio = imgH / imgW;

		if (imgRatio > contRatio) {
			if (!$secteurHeaderImg.hasClass("is-parallaxed")) {
				$secteurHeaderImg
					.removeClass("cv")
					.removeClass("cv-img")
					.removeClass("cv-top")
					.addClass("is-parallaxed");
				parallax_update_els();
			}
		} else {
			if ($secteurHeaderImg.hasClass("is-parallaxed")) {
				$secteurHeaderImg
					.addClass("cv")
					.addClass("cv-img")
					.addClass("cv-top")
					.removeClass("is-parallaxed")
					.removeAttr("style");
				parallax_update_els();
			}
		}
	}

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Secteur : crop texte mobile
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	var $secteurTextToCrop = $main.find(".secteur_text.to-crop");
	var secteur_text_has_been_shown = false;

	function secteur_text_crop_init() {
		if ($secteurTextToCrop.hasClass("is-crop")) return;

		$secteurTextToCrop.addClass("is-crop");
		$secteurTextToCrop.append(
			'<p class="secteur_cropShow"><button type="button">Lire la présentation complète</button></p>'
		);
		$secteurTextToCrop
			.find(".secteur_cropShow button")
			.on("click", secteur_text_crop_show);
	}

	function secteur_text_crop_destroy() {
		if (!$secteurTextToCrop.hasClass("is-crop")) return;

		$secteurTextToCrop.removeClass("is-crop");
		$secteurTextToCrop.find(".secteur_cropShow button").off().parent().remove();
	}

	function secteur_text_crop_show(event) {
		event.preventDefault();
		secteur_text_has_been_shown = true;
		secteur_text_crop_destroy();
	}

	function secteur_text_check() {
		if (secteur_text_has_been_shown || !$secteurTextToCrop.length) return;
		// var cond = is_mobile;
		var cond =
			Math.abs($secteurTextToCrop.outerWidth() - windowW) < 10 &&
			$secteurTextToCrop.get(0).scrollHeight -
				($secteurTextToCrop.hasClass("is-crop")
					? 0
					: parseFloat($secteurTextToCrop.css("padding-bottom"))) -
				50 >
				360;
		console.log(
			cond,
			$secteurTextToCrop.get(0).scrollHeight,
			parseFloat($secteurTextToCrop.css("padding-bottom"))
		);
		if (cond) {
			secteur_text_crop_init();
		} else {
			secteur_text_crop_destroy();
		}
	}

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Encart contenu (juste pour supprimer la marge en trop)
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	$main.find(".txtFocus .media").each(function (idx, el) {
		var $el = $(el);
		var $parent = $el.closest(".txtFocus");
		$parent.append($el);
		$el.prev().addClass("txtFocus_lastText");
	});

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Vidéos
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	var plyr_is_ready = false;
	var plyr_js_is_ready = false;
	var plyr_css_is_ready = false;
	var plyr_js_is_loading = false;
	var plyr_css_is_loading = false;

	function init_videos() {
		var $videosIframes = $body.find(".js-plyr");
		if (!$videosIframes.length) return;
		video_load_plyr();
	}
	init_videos();

	function video_load_plyr() {
		if (plyr_is_ready) {
			init_plyr_on_video();
			return;
		}

		if (!plyr_js_is_loading) {
			plyr_js_is_loading = true;
			async(url.theme + "js/vendor/plyr.polyfilled.min.js", plyr_js_loaded);
		}
		if (!plyr_css_is_loading) {
			plyr_css_is_loading = true;
			loadCSS(url.theme + "js/vendor/plyr.css", plyr_css_loaded);
		}
	}

	function plyr_js_loaded() {
		plyr_js_is_ready = true;
		check_plyr_is_ready();
	}

	function plyr_css_loaded() {
		plyr_css_is_ready = true;
		check_plyr_is_ready();
	}

	function check_plyr_is_ready() {
		if (!plyr_is_ready && plyr_js_is_ready && plyr_css_is_ready) {
			plyr_is_ready = true;
			init_plyr_on_video();
		}
		return plyr_is_ready;
	}

	function init_plyr_on_video() {
		$body.find(".js-plyr").each(function (idx, el) {
			if ($(el).hasClass("iframe")) {
				$(el)
					.addClass("plyr__video-embed")
					.removeClass("iframe")
					.children("span")
					.remove();
			}
		});
		// https://github.com/sampotts/plyr
		var players = Plyr.setup(".js-plyr", {
			controls: [
				"play-large",
				"play",
				"progress",
				"current-time",
				"mute",
				"volume",
			],
		});
		$.each(players, function (idx, el) {
			if (el.elements.container) {
				var poster = $(el.elements.container)
					.closest('[data-plyr-poster]:not([data-plyr-poster=""])')
					.data("plyr-poster");
				if (poster) {
					el.poster = poster;
				}
			}
		});
	}

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Maps : Variables
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	var $maps = $main.find(".js-gogoMap"),
		maps_styles = [
			{
				featureType: "water",
				elementType: "geometry",
				stylers: [{ color: "#e9e9e9" }, { lightness: 17 }],
			},
			{
				featureType: "landscape",
				elementType: "geometry",
				stylers: [{ color: "#f5f5f5" }, { lightness: 20 }],
			},
			{
				featureType: "road.highway",
				elementType: "geometry.fill",
				stylers: [{ color: "#ffffff" }, { lightness: 17 }],
			},
			{
				featureType: "road.highway",
				elementType: "geometry.stroke",
				stylers: [{ color: "#ffffff" }, { lightness: 29 }, { weight: 0.2 }],
			},
			{
				featureType: "road.arterial",
				elementType: "geometry",
				stylers: [{ color: "#ffffff" }, { lightness: 18 }],
			},
			{
				featureType: "road.local",
				elementType: "geometry",
				stylers: [{ color: "#ffffff" }, { lightness: 16 }],
			},
			{
				featureType: "poi",
				elementType: "geometry",
				stylers: [{ color: "#f5f5f5" }, { lightness: 21 }],
			},
			{
				featureType: "poi.park",
				elementType: "geometry",
				stylers: [{ color: "#dedede" }, { lightness: 21 }],
			},
			{
				elementType: "labels.text.stroke",
				stylers: [{ visibility: "on" }, { color: "#ffffff" }, { lightness: 16 }],
			},
			{
				elementType: "labels.text.fill",
				stylers: [{ saturation: 36 }, { color: "#333333" }, { lightness: 40 }],
			},
			{ elementType: "labels.icon", stylers: [{ visibility: "off" }] },
			{
				featureType: "transit",
				elementType: "geometry",
				stylers: [{ color: "#f2f2f2" }, { lightness: 19 }],
			},
			{
				featureType: "administrative",
				elementType: "geometry.fill",
				stylers: [{ color: "#fefefe" }, { lightness: 20 }],
			},
			{
				featureType: "administrative",
				elementType: "geometry.stroke",
				stylers: [{ color: "#fefefe" }, { lightness: 17 }, { weight: 1.2 }],
			},
		];

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Maps : Chargement de google map
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	function async(script, callback) {
		var s = document.createElement("script"),
			head = document.getElementsByTagName("head")[0];
		s.type = "text/javascript";
		s.async = true;
		s.defer = true;
		s.src = script;
		if (typeof callback == "function") {
			s.addEventListener(
				"load",
				function (e) {
					callback.call();
				},
				false
			);
		}
		head.appendChild(s);
	}

	function maps_init() {
		if (!$maps.length) return;
		var gmapcallback = function () {
			maps_setup();
		};
		window.gmapcallback = gmapcallback;
		async(
			"//maps.googleapis.com/maps/api/js?key=" + keys.gm + "&callback=gmapcallback"
		);
	}
	maps_init();

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Maps : création
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	function maps_setup() {
		if (!$maps.length) return;
		$maps.each(function (idx, gogomap) {
			maps_render(gogomap);
		});
	}

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Maps : rendu
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	function maps_render(gogomap) {
		var $gogomap = $(gogomap),
			lat = $gogomap.attr("data-lat"),
			lng = $gogomap.attr("data-lng"),
			zoom = $gogomap.attr("data-zoom")
				? parseInt($gogomap.attr("data-zoom"))
				: 17,
			latlng = new google.maps.LatLng(lat, lng),
			args = {
				zoom: zoom,
				center: latlng,
				// scrollwheel : false,
				gestureHandling: "cooperative",
				// draggable : true,
				mapTypeId: google.maps.MapTypeId.ROADMAP,
				styles: maps_styles,
				streetViewControl: false,
				overviewMapControl: false,
				mapTypeControl: false,
				panControl: false,
				// scaleControl : false,
				zoomControlOptions: { style: google.maps.ZoomControlStyle.SMALL },
			},
			map = new google.maps.Map(gogomap, args),
			// image = 'img/map-icon.png',
			marker = new google.maps.Marker({
				position: latlng,
				map: map,
			});
		google.maps.event.addDomListener(window, "resize", function () {
			map.setCenter(latlng);
		});
	}

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Keys event
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	$html.on("keydown", function (event) {
		if (event.which == 27) {
			if ($html.hasClass("has-fullscreen")) {
				fullscreen_close();
			} else if ($html.hasClass("has-modal")) {
				modale_close();
			} else if ($html.hasClass("has-subnavOpen")) {
				closeSubMenu();
			} else if ($html.hasClass("has-menuOpen")) {
				menu_close();
			}
		}
	});

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//On resize
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	$window
		.on("resize", function (event) {
			update_dimensions();
			init_grid();
			secteur_header_update();
			parallax_update();
			home_actus_update_height_cont();
			acc_content_check();
			page_nav_mobile_move_check();
			secteur_text_check();
		})
		.trigger("resize");

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//On scroll
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	var ticking = false;
	$window
		.on("scroll", function (event) {
			update_scroll();
			requestTick();
		})
		.trigger("scroll");

	function requestTick() {
		if (!ticking) {
			ticking = true;
			w.requestAnimationFrame(trigger_scroll);
		}
	}

	function trigger_scroll() {
		update_header();
		update_page_nav_fix();
		parallax_update();
		ticking = false;
	}

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	// Texte caché avec bouton
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	function open_contenu_init() {
		var $contenu = $(".page_section_contenu");
		var $formateurs = $(".formation-formateurs");

		if ($contenu.length !== 0 && $contenu.outerHeight() >= 400) {
			var $link_contenu = $(
				'<button class="link_discover bg">Afficher tout le contenu</button>'
			);

			$contenu.addClass("has-gradient");

			$contenu.after($link_contenu);

			$link_contenu.on("click", function (event) {
				if ($contenu.hasClass("is_animate")) {
					return;
				}
				if (!$contenu.hasClass("is_open")) {
					$contenu.addClass("is_open");
					var curHeight = $contenu.height();
					var autoHeight = $contenu.css("max-height", "100%").outerHeight();
					$contenu.height(curHeight).animate(
						{
							height: autoHeight,
						},
						800,
						function () {
							$contenu.removeClass("is_animate");
						}
					);
					event.preventDefault();
				} else {
					$contenu.removeClass("is_open");
					$contenu.animate(
						{
							height: 400,
						},
						800,
						function () {
							$contenu.css({ "max-height": "400px", height: "auto" });
							$contenu.removeClass("is_animate");
						}
					);
					event.preventDefault();
				}
			});
		}

		if ($formateurs.length !== 0 && $formateurs.outerHeight() >= 300) {
			var $link_formateurs = $(
				'<button class="link_discover bg">Afficher tout le contenu</button>'
			);

			$formateurs.addClass("has-gradient");

			$formateurs.after($link_formateurs);

			$link_formateurs.on("click", function (event) {
				if ($formateurs.hasClass("is_animate")) {
					return;
				}
				$formateurs.addClass("is_animate");
				if (!$formateurs.hasClass("is_open")) {
					$formateurs.addClass("is_open");
					var curHeight = $formateurs.height();
					var autoHeight = $formateurs.css("max-height", "100%").outerHeight();
					$formateurs.height(curHeight).animate(
						{
							height: autoHeight,
						},
						800,
						function () {
							$formateurs.removeClass("is_animate");
						}
					);
					event.preventDefault();
				} else {
					$formateurs.removeClass("is_open");
					$formateurs.animate(
						{
							height: 300,
						},
						800,
						function () {
							$formateurs.css({ "max-height": "300px", height: "auto" });
							$formateurs.removeClass("is_animate");
						}
					);
					event.preventDefault();
				}
			});
		}
	}
	open_contenu_init();
})(jQuery);
